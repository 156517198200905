import React from 'react';

import './OurTeam.css';

import TeamCard from './components/TeamCard/TeamCard';
import person1Img from '../../../utils/assets/aboutUs/person1.png';
import person2Img from '../../../utils/assets/aboutUs/person2.png';
import person3Img from '../../../utils/assets/aboutUs/person3.png';
import person4Img from '../../../utils/assets/aboutUs/person4.png';
import person5Img from '../../../utils/assets/aboutUs/person5.png';
import person6Img from '../../../utils/assets/aboutUs/person6.png';

import backgroundDesing1 from '../../../utils/assets/logo/background-design-left.png';
import backgroundDesing2 from '../../../utils/assets/logo/background-design2.png';


const OurTeam = ({ props }) => {
   const theTeamInfo = [
      {
         personImg: person1Img,
         personName: 'Rey Colon',
         personJob: 'Founder & Chief Executive Officer'
      },
      {
         personImg: person2Img,
         personName: 'Jesse Roach',
         personJob: 'Co-founder & Chief Technology Officer'
      },
      {
         personImg: person3Img,
         personName: 'Alex Trican',
         personJob: 'Co-founder & Chief Security Officer'
      },
      {
         personImg: person4Img,
         personName: 'Wayne Orchard',
         personJob: 'Partner & Executive Vice President'
      },
      {
         personImg: person5Img,
         personName: 'James Bulls',
         personJob: 'Senior Vice President Sales'
      },
      {
         personImg: person6Img,
         personName: 'Stefanie Manley',
         personJob: 'Vice President, Clinical Operations'
      }
   ]
   return (
      <section className="OurTeam__main-container" >
         <div className="OurTeam__background-design-1">
            <div>
               <img src={backgroundDesing1} alt="bg-design-1" className="OurTeam__design1" />
            </div>
         </div>
         <div className="OurTeam__background-design-2">
            <div>
               <img src={backgroundDesing2} alt="bg-design-2" className="OurTeam__design2" />
            </div>
         </div>
         <div className='OurTeam__inner-container'>
            <div className='OurTeam__container'>
               {
                  theTeamInfo.map((card, index) => (
                     <TeamCard key={index} props={card} />
                  ))
               }
            </div>
         </div>
      </section>
   )

}
export default OurTeam;