import React, { useEffect } from "react";


import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import SectionInfo from '../../shared/components/SectionInfo/SectionInfo';
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";


import doctorsWorkingOnTelehealthImg from '../../utils/assets/price/doctors-working-on-telehealth.png';


 

const Price = props => {
    useEffect(()=>{
        window.scroll(0,0);
     },[])
     const bannerSectionInfo =
     {
        cardTitle: "Access to a national network of licensed physicians",
        cardButtonText: "Try Now",
        lengendBackground: doctorsWorkingOnTelehealthImg
     }


     const subscriptionInformation = [
        {       
            subscriptionType: "BASIC",
            subscriptionPrice: "39.95",
            subscriptionTrialInfo: "Trial for 30 days at",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {       
            subscriptionType: "PREMIUM",
            subscriptionPrice: "49.95",
            subscriptionTrialInfo: "Trial for 30 days at",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }
    ]

    return ( 
        <React.Fragment>
             <BannerSection props={bannerSectionInfo}/>
             <NavegationInfo page="price"/>
             <SectionInfo title={"Get a 30-day trial version for ONLY $3.29"} color={'#277a8c'} />
             <SubscriptionPlan props={subscriptionInformation}/>
        </React.Fragment>
    );            
};

export default Price;