export const PROJECT = {
    DOMAIN : 'www.oncallhealthgroup.com',
    MERCHAN_ID : 53,
    AMOUNT : '',
    BUILD_ORDER : '1',
    CAPTURA_DELAY : '0',
    PRODUCT_COUNT : '1',
    PRODUCT_ID : '6',
    PRODUCT_KU_1 : 'onCallHealthGroup_product1',
    PRODUCT_QT_Y_1 : '1',
    BINCHECK : '1',
    SKIP : '1'
}