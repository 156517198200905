import React from "react";

import './BenefitCard.css';
const BenefitCard = ({ props }) => {



    return (
        <div className="BenefitCard__main-container">
            <div className="BenefitCard__inner-container">
                <div className="BenefitCard__header-container">
                    <div className="BenefitCard__media-container">
                        <div className="BenefitCard__icon-container">
                            <img src={props.cardIcon} alt="" className="BenefitCard__icon" />
                        </div>
                    </div>
                </div>
                <div className="BenefitCard__divider"></div>
                <div className="BenefitCard__about-container">
                <div className="BenefitCard__description-container">
                    <div >
                        <p>
                            {props.cardTitle}
                        </p>
                    </div>
                </div>
                <div className="BenefitCard__about-information">
                    <div className="BenefitCard__information">
                        <p>
                            {props.cardDescription}
                        </p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default BenefitCard; 