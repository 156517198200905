import React from "react";

import BenefitCard from "./components/BenefitCard/BenefitCard";

import primaryCareIcon from '../../../utils/assets/home/icons/primary-care.png';
import urgentCareIcon from '../../../utils/assets/home/icons/urgent-care.png';
import mentalHealthIcon from '../../../utils/assets/home/icons/mental-health.png';
import careNavigationIcon from '../../../utils/assets/home/icons/care-navigation.png';
import dermatologyIcon from '../../../utils/assets/home/icons/dermatology.png';
import physicalTherapistsIcon from '../../../utils/assets/home/icons/physical-therapists.png';
import assistenceIcon from '../../../utils/assets/home/icons/assistance-24-7.png';
import medicalPrescriptionsIcon from '../../../utils/assets/home/icons/medical-prescriptions.png';
import specialtyReferralIcon from '../../../utils/assets/home/icons/specialty-referral.png';
import virtualConsulation from '../../../utils/assets/home/icons/virtual-consultation.png';

import backgroundDesign1 from '../../../utils/assets/home/background-design1.png';
import backgroundDesign2 from '../../../utils/assets/home/background-design2.png';
import backgroundDesign3 from '../../../utils/assets/home/background-design-right.png';
import backgroundDesign4 from '../../../utils/assets/home/background-design-left.png';
import backgroundDesign5 from '../../../utils/assets/home/background-design2.png';



import './Benefit.css';
const Benefit = ({ props }) => {

    const benefitCardInformation = [
        {
            cardTitle: "Primary Care",
            cardIcon: primaryCareIcon,
            cardDescription: "Choose a primary care physician to manage your medical treatments, medications, and preventive care."
        },
        {
            cardTitle: "Urgent Care",
            cardIcon: urgentCareIcon,
            cardDescription: "Reduce ER visits. Now you can get a quick and efficient diagnosis of your health status in the moment you need it."
        },
        {
            cardTitle: "Mental Health",
            cardIcon: mentalHealthIcon,
            cardDescription: "Without leaving your home, you can access a wide variety of therapists with same-day consultations."
        },
        {
            cardTitle: "Care Navigation",
            cardIcon: careNavigationIcon,
            cardDescription: "We can help you find providers, get second opinions, learn to take medications, and answer questions."
        },
        {
            cardTitle: "Dermatology",
            cardIcon: dermatologyIcon,
            cardDescription: "Treat the most common skin, nail, and hair conditions right away and start a healthy routine."
        },
        {
            cardTitle: "Physical Therapists",
            cardIcon: physicalTherapistsIcon,
            cardDescription: "To help you prevent injury and reduce pain, we offer personalized care plans with simple steps."
        },
        {
            cardTitle: "Assistance 24 - 7 - 365",
            cardIcon: assistenceIcon,
            cardDescription: "Get support from anywhere in the world, 24 hours a day, 7 days a week, 365 days a year."
        },
        {
            cardTitle: "Medical Prescriptions",
            cardIcon: medicalPrescriptionsIcon,
            cardDescription: "You can get the prescriptions you need for your medical treatment right away and buy them at your local pharmacy."
        },
        {
            cardTitle: "Specialty Referral",
            cardIcon: specialtyReferralIcon,
            cardDescription: "Get the medical care you need by being immediately referred to the right care provider."
        },
        {
            cardTitle: "Virtual Consultation",
            cardIcon: virtualConsulation,
            cardDescription: "Our virtual telemedicine services provide a unique, hassle-free medical experience that can prevent unnecessary ER visits"
        },


    ]


    return (
        <section className="Benefit__main-contiainer">
            <div className="Benefit__background-design-1">
                <div>
                    <img src={backgroundDesign1} className="Benefit__desing1" />
                </div>
            </div>
            <div className="Benefit__background-design-2">
                <div>
                    <img src={backgroundDesign2} className="Benefit__desing2" />
                </div>
            </div>
            <div className="Benefit__background-design-3">
                <img src={backgroundDesign3} className="Benefit__desing3" />
            </div>

            <div className="Benefit__inner-contianer">
                {
                    benefitCardInformation.map((cardInfo, index) => (
                        <BenefitCard key={index} props={cardInfo} />
                    ))
                }
            </div>
            <div className="Benefit__background-design-4">
                <div>
                    <img src={backgroundDesign4} className="Benefit__desing4" />
                </div>
            </div>
            <div className="Benefit__background-design-5">
                <div>
                    <img src={backgroundDesign5} className="Benefit__desing5" />
                </div>
            </div>
        </section>
    );
};

export default Benefit; 