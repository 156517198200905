import React from "react";

import './ContactUs.css'

import ContactInformation from '../../../shared/components/ContactInformation/ContactInformation';
import ContactForm from '../ContactForm/ContactForm';

import backgroundDesign1 from '../../../utils/assets/logo/background-design2.png';
import backgroundDesign2 from '../../../utils/assets/logo/background-design3.png';
import backgroundDesign3 from '../../../utils/assets/logo/background-design-left.png';



const ContactUs = ({props}) => {

    const contactInformation ={
        contactInformationTitle : "Please feel free to contact us at any time!",
        contactInformationWeek : "Monday - Friday 9:00 AM - 09:00 PM EST",
        contactInformationWeekend : "Saturday - Sunday 9:00 AM - 05:00 PM EST"
    }

    return (
        <section className="ContactUs__main-container">
            <div className="ContactUs__background-design-1"> 
                    <div>
                        <img src={backgroundDesign1} alt="bg-design-1" className="ContactUs__design1"/>
                    </div>
                </div>
                <div className="ContactUs__background-design-2">
                    <div>
                        <img src={backgroundDesign2} alt="bg-design-2" className="ContactUs__design2"/>
                    </div>
                </div>
               
                <div className="ContactUs__contact-information-container">
                    <div className="ContactUs__contact-information">
                       <ContactInformation props={contactInformation} />
                    </div>
                </div>
                <div className="ContactUs__contact-form-container">
                    <div className="ContactUs__contact-form">
                        <ContactForm></ContactForm>
                    </div>
                </div> 
                <div className="ContactUs__background-design-3">
                    <div>
                        <img src={backgroundDesign3} alt="bg-design-3" className="ContactUs__design3"/>
                    </div>
                </div> 
        </section>
    );
};

export default ContactUs;