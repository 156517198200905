import React from "react";
import './MembershipTerms.css'

import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const MembershipTerms = props => {


    return (
        <>
            <section className="Membership__main-section-container">
                <div>
                    <h1 className="text-center Membership__main-title"> Terms of membership</h1>
                </div>
                <div className="Membership__section-description">
                    <p>
                        Welcome to the <strong>ON CALL HEALTH GROUP LLC</strong> (“The Company”) website (the “Site”). <strong> ON CALL HEALTH GROUP LLC</strong> is an authorized reseller of
                        MyTelemedicine Inc’s services. The Company provides online health-related services by arranging for video, telephone, or electronic mail medical
                        consultations with a licensed physician, providing electronic medical record storage, and other related products and services (collectively, the “Services”).
                        <b>
                            Company does not in any way provide medical services of any kind. The Company’s business is exclusively providing
                            referrals for the services, for which the Company charges a fee.
                        </b>
                    </p>
                    <p></p>
                    <p>
                        When you place your order, you will be enrolled in the <strong>ON CALL HEALTH GROUP LLC</strong>  program and will be entitled to access all of the money-saving
                        benefits and Telemedicine services available to program members around the clock for 30 days from the date of your enrollment. You will be charged a
                        non-refundable Introductory Processing Fee of $3.29 which covers your introductory membership period. At the end of the introductory period, your
                        monthly membership fee will be automatically billed to your designated billing source. If you decide to cancel your membership within the trial period,
                        simply call the toll-free number listed in your membership information and welcome letter, and you will only be responsible for the initial processing fee.
                    </p>
                    <p></p>
                    <p>
                        As used in these Terms and Conditions, “The Company” means and includes The Company, its parent, subsidiaries and affiliated entities, “you” or “your”
                        means you, your dependent(s), if any, and any other person eligible for access to your The Company account. These Terms and Conditions apply to your
                        use of the Site and, to the extent referenced, to your registration for, enrollment in to, and use of The Company Services.
                    </p>
                    <p></p>
                    <p>
                        The Company may, at any time and from time to time, in its sole discretion, change these Terms and Conditions. Any changes to these Terms and
                        Conditions will be effective immediately upon posting of the changed Terms and Conditions on the Site. You agree to review these Terms and Conditions
                        periodically, and your use of the Site following any such change constitutes acceptance of all Terms and Conditions.
                    </p>
                    <p></p>
                    <p><b>IF YOU ARE EXPERIENCING A MEDICAL EMERGENCY, DIAL “911” IMMEDIATELY.</b></p>
                    <p></p>
                    <p>
                        The Company provides access to clinical services. The Doctors providing these clinical services are part of independent, physician-owned medical groups.
                        Healthcare providers accessed through the Site are not employed by The Company. <b> THE COMPANY DOES NOT PROVIDE MEDICAL CARE. THE
                            CONTENT OF THE SITE AND THE SERVICES DO NOT CONSTITUTE PROFESSIONAL MEDICAL ADVICE, DIAGNOSIS OR TREATMENT. RELIANCE
                            ON ANY INFORMATION APPEARING ON THE SITE, WHETHER PROVIDED BY The Company, ITS AFFILIATES, ITS CONTENT PROVIDERS,
                            MEDICAL PRACTITIONERS, CLIENTS OR OTHER VISITORS TO THE SITE IS SOLELY AT YOUR OWN RISK AND IS NOT A SUBSTITUTE FOR
                            MEDICAL OR OTHER PROFESSIONAL ADVICE AND SERVICES FROM QUALIFIED HEALTH CARE PROVIDERS ASSESSING YOUR CONDITION IN
                            PERSON. WHILE THE COMPANY FACILITATES CONSULTATIONS WITH LICENSED PHYSICIANS, THE COMPANY IS NOT IN THE PRACTICE OF
                            MEDICINE, DOES NOT PROVIDE MEDICAL SERVICES AND THE DOCTOR-PATIENT RELATIONSHIP IS BETWEEN YOU AND THE HEALTHCARE
                            PROVIDER YOU SELECT. </b> Your use of this Site does not in any way create a physician-patient relationship.
                    </p>
                    <p></p>
                    <p>
                        Subject to your compliance with these Terms and Conditions, your acknowledgement of the Privacy Policy on The Company Site and your payment of
                        any applicable fees, The Company grants you a limited, revocable, non-exclusive, non-transferable and non-sublicensable license to access and make
                        personal use of the Site and The Company Services. With the exception of your Electronic Medical Records, The Company retains all right, title and
                        interest in, and to The Company, the Services and any Information, products, documentation, software or other materials on the Site. You agree not to
                        reproduce, re-transmit, distribute, disseminate, sell, publish, broadcast, or circulate such information to anyone. Use, reproduction, copying, or
                        redistribution of The Company’s trademarks, service marks and logos is strictly prohibited without the prior written permission of The Company.
                    </p>
                    <p></p>
                    <p>
                        You agree not to access the Site or use the Services in an unlawful way or for any unlawful purpose, including but not limited to: posting or transmitting a
                        message under a false name, posting any data, materials, content or information which is libelous, defamatory, obscene, fraudulent, false or contrary to
                        the ownership or intellectual property rights of any other person, or positing any content which contains or promotes any virus, worm, Trojan horse, time
                        bomb or other computer programming or code that is designed or intended to damage, destroy, interfere or manipulate in any way, or otherwise interrupt
                        or expropriate the Site or the Services, personal information, software, equipment, servers or Information or facilitate or promote hacking or similar
                        conduct. The Company may delete any Information provided by you that it deems in its sole discretion fraudulent, abusive, defamatory, and obscene or in
                        violation of a copyright, trademark or other intellectual property or ownership right of any other person.
                    </p>
                    <p></p>
                    <p>
                        You represent that you are of legal age and are not a person barred from enrolling for, and/or receiving, Services under the laws of the United States or
                        other applicable jurisdictions in which you may be located. Access to and use of the Site and/or The Company Services is limited to users in the United
                        States. In the event of access and/or use outside of the United States, you agree to comply with all local and federal laws regarding online conduct and
                        transmission of information, including, without limitation, all applicable laws regarding the transmission of technical data exported from the United States
                        or any other jurisdiction, and you acknowledge that The Company makes no representation or warranty that we, or any of the Services, or any
                        information on the Site or transmitted via a Service is lawful in any jurisdiction outside the United States. The Company may, in its sole discretion, refuse
                        to accept your registration or membership due to non-compliance with any eligibility or membership requirement.
                    </p>
                    <p></p>
                    <p>
                        In order to enroll in the The Company Services, you are required to (1) fully, accurately and truthfully complete the registration profile, including your
                        Personal Contact Information (“PCI”), including but not limited to name, address, telephone number and e-mail address, (2) complete any and all other
                        items requested pursuant to your use of the Site or the Services and (3) set your Internet browser to accept “cookies” from the Site. By registering on the
                        Site you are agreeing to opt-in to the receipt of communications from The Company, such as newsletters and offers, by mail or electronic mail and further
                        agree that The Company may provide to you, electronically, any notices required by law. If you do not wish to receive such communications, you may
                        opt-out at any time. You are also consenting to The Company’s disclosure of your PCI with agents and The Company employees for any reason as
                        described in our Privacy Notice.
                    </p>
                    <p></p>
                    <p>
                        You are solely responsible for maintaining the confidentiality of your password and for all activities that occur under your password. You agree to prohibit
                        anyone else from using your password and to immediately notify The Company in writing of any unauthorized use of your password or other security
                        concerns of which you become aware. You will indemnify Company for any unauthorized use or transmission of your password to any third parties.
                    </p>
                    <p></p>
                    <p>
                        In order to conduct a proper consultation, The Company creates an Electronic Medical Record (“EMR”) specifically for you to store and access your
                        personal health information online, and for the licensed physician to review as part of the consult. Information provided to a physician through the
                        Company becomes part of The Company EMR and is subject to applicable state and federal law. <b>PLEASE SEE HIPAA NOTICE BELOW. IT IS SOLELY
                            YOUR PHYSICIAN’S OBLIGATION TO COMPLY WITH ALL STATE AND FEDERAL LAWS INCLUDING, BUT NOT LIMITED TO HIPAA LAWS IN THE
                            USE AND DISCLOSURE OF THE INFORMATION OBTAINED THROUGH THE COMPANY’S EMR.</b> The Company archives all Personal Information and
                        EMRs associated with usage of The Company site and The Company services for Seven (7) years.
                    </p>
                    <p></p>
                    <p>
                        If your membership is paid by a third party and that third party ceases to, for any reason, pay for your membership or any other applicable fee, you may
                        continue your membership  to the Services provided you immediately make arrangements to pay the Company directly. As an individual enrollee, you may
                        upgrade or downgrade the level of the Services to which you are enrolling at any time by following the instructions on the Site, which shall take effect
                        by your next full billing cycle. Memberships provided by a third party may only be upgraded pursuant to direct payment to The Company. Company may
                        cancel your membership at any time. The Company has sole and absolute discretion in determining whether or no to issue a refund.
                    </p>
                    <p></p>
                    <p>
                        The Company has sole discretion to withdraw, suspend or discontinue any functionality or feature of the Site or the Services, including the site itself at
                        any time. Additionally, The Company makes no representations, warranties or guarantees whatsoever as to the correctness or accuracy of the Site and
                        contents therein. The Company may provide links to third parties’ web sites but is not responsible, under any circumstance, for an errors related to the
                        content or functionality of third party sites nor is it responsible for the terms and conditions, privacy practices, accuracy, copyright compliance, legality,
                        decency or any of the Information, products, services or any transmissions received by or through third parties, or for any viruses or cyber-attack your
                        technology may suffer by visiting these third-party sites.
                    </p>
                    <p></p>
                    <p>
                        Any link on the Site to any third party’s Web site does not imply an endorsement of the terms, privacy practices, or products or services of any kind of
                        any third party. If you are a copyright owner, authorized to act on behalf of one, or authorized to act under any exclusive right under copyright, please
                        report alleged copyright infringements taking place on or through our Site by sending us written notice pursuant to Section 6 below. The Company
                        reserves the right, in its sole discretion, to at any time, terminate your access to all or part of the Site, with or without cause, and with or without notice.
                    </p>
                    <p></p>
                    <p>
                        The Company makes no representation or warranty as to the content of any treatment response from any participating, licensed, physician. You
                        understand that Company is solely a referral service and does not warranty the validity of the information provided to you. You also understand that a
                        doctor-patient relationship will never arise between you and the Company. You and your physician are solely responsible for all information and/or
                        communication sent during a telephone medical consultation, secure email consultation or other communication.
                    </p>
                    <p></p>
                    <p>
                        The Company does not guarantee that a telephone-based medical consultation or secure email, or web-based, consultation is the appropriate course of
                        treatment for your particular health care problem. You agree to contact your primary care physician immediately should your condition change or your
                        symptoms worsen. <b> IN ORDER TO CREATE A DOCTOR-PATIENT RELATIONSHIP WITH A PARTICIPATING PHYSICIAN YOU MUST COMPLETE A
                            MEDICAL HISTORY ASSESSMENT.
                        </b>
                    </p>
                        <p></p>
                        <p>
                            <b>

                                ALL INFORMATION, PRODUCTS, AND SERVICES CONTAINED ON OR PROVIDED THROUGH THIS SITE ARE PROVIDED “AS IS” WITHOUT ANY
                                WARRANTY OF ANY KIND, EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE UNDER APPLICABLE LAW THE COMPANY AND ITS
                                MEMBERS, SHAREHOLDERS, DIRECTORS, MANAGERS, EMPLOYEES, OFFICERS, OFFICERS, CONTRACTORS, AGENTS, AND REPRESENTATIVES
                                (COLLECTIVELY, “AFFILIATES”) HEREBY DISCLAIM ALL REPRESENTATIONS AND WARRANTIES, EXPRESS OR IMPLIED, STATUTORY OR
                                OTHERWISE, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-
                                INFRINGEMENT AND FREEDOM FROM COMPUTER VIRUS STRAINS. WITHOUT LIMITING THE FOREGOING, THE COMPANY MAKES NO
                                ARRANTY AS TO THE RELIABILITY, ACCURACY, TIMELINESS, USEFULNESS, ADEQUACY, COMPLETENESS OR SUITABILITY OF THE
                                INFORMATION, PRODUCTS, AND SERVICES PROVIDED HEREUNDER. WITHOUT LIMITATION OF THE FOREGOING, THE COMPANY
                                SPECIFICALLY DISCLAIMS ALL LIABILITY ACTS OR OMISSIONS OF ANY PARTICIPATING PHYSICIANS PROVIDING MEDICAL CARE TO
                                MEMBERS.
                            </b>
                        </p>
                        <p></p>
                        <p>
                             <b>
                             IN THE EVENT COMPANY SHOULD BE FOUND LIABLE, DAMAGES WILL BE LIMITED TO (i) ONE MONTH’S (1 MONTH) MEMBERSHIP RATE OF
                             YOUR MEMBERSHIP PLAN, OR (ii) IN THE EVENT NO MEMBERSHIP PRICE WAS PAID OR REQUIRED, $1,000. IN ADDITION, TO THE 
                             MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE COMPANY BE LIABLE FOR ANY SPECIAL, PUNITIVE, INDIRECT, INCIDENTAL
                             OR CONSEQUENTIAL DAMAGES OR ATTORNEYS FEES OR COSTS, INCLUDING BUT NOT LIMITED TO PERSONAL INJURY, WRONGFUL DEATH,
                             LOSS OF USE, LOSS OF PROFITS, INTERRUPTION OF THE SERVICES OR LOSS OF INFORMATION, WHETHER IN ANY ACTION IN WARRANTY, 
                             CONTRACT, TORT (INCLUDING, BUT NOT LIMITED TO NEGLIGENCE OR FUNDAMENTAL BREACH), OR OTHERWISE ARISING OUT OF OR IN 
                             ANY WAY CONNECTED WITH THE USE OF, OR THE INABILITY TO USE THIS SITE OR ANY SERVICE OFFERED OR PROVIDED THROUGH THIS 
                             SITE OR BY OTHER MEANS OR ANY INFORMATION OR PRODUCTS CONTAINED IN, ACCESSED THROUGH, OR PRODUCTS PURCHASED OR 
                             SERVICES ENROLLED TO ON THIS SITE OR OTHERWISE, EVEN IF AN AUTHORIZED REPRESENTATIVE OF THE COMPANY IS ADVISED OF THE
                             LIKELIHOOD OR POSSIBILITY OF THE SAME.
                             </b>
                        </p>
                        <p>
                            <b>
                            TO THE EXTENT CERTAIN JURISDICTIONS RESTRICT ANY OF THE ABOVE LIMITATIONS OF LIABILITY; SUCH LIMITATIONS SHALL NOT APPLY IN
                            SUCH JURISDICTIONS TO THE EXTENT OF SUCH RESTRICTIONS. YOU ACKNOWLEDGE AND AGREE THAT COMPANY’S AGGREGATE LIABILITY
                            TO YOU FOR ANY DAMAGES, LOSSES, FEES, CHARGES, EXPENSES OR LIABILITIES AND IN CIRCUMSTANCES WHERE THE FOREGOING
                            LIMITATION IS FINALLY DETERMINED TO BE UNAVAILABLE SHALL NOT EXCEED THE MEMBERSHIP PRICE PAID BY YOU FOR THE ONE (1)
                            MONTH PERIOD PRIOR TO THE TIME OF THE FIRST OCCURRENCE OF THE SUBJECT DAMAGES, LOSSES, FEES, CHARGES, EXPENSES OR
                            LIABILITIES.
                            </b>
                        </p>
                        <p></p>
                        <p>
                        You agree to defend, indemnify and hold harmless The Company and its Affiliates from and against any and all rights, demands, losses, liabilities, damages,
                        claims, causes of action, actions and suits (no matter whether at law or equity), fees, costs and attorneys’ fees (including appellate level) of any kind 
                        whatsoever arising, directly or indirectly, out of or in connection with (i) your use or misuse or of the Site or the Services or any Information posted on the
                        Site, (ii) your membership, (iii) your breach of the Terms and Conditions or the Privacy Notice, (iv) your relationship with any participating physician, (v) the
                        content or subject matter of or any Information you provide to The Company, any of its Affiliates, any participating physician or Customer service agent, 
                        and/or (vi) any negligent or wrongful action or omission by you in the use or misuse of the Site or the Services or any Information posted on the Site, 
                        including, without limitation, infringement of third party intellectual property rights, privacy rights, or negligent or wrongful conduct.
                        </p>
                        <p></p>
                        <p>
                        Any controversy or claim against the Company arising out of or relating to this contract, or the alleged breach thereof, shall be settled by arbitration
                        administered by the American Arbitration Association. The number of arbitrators shall be one or three. The cost of Arbitration including, but not limited to
                        all attorneys fees will be paid by you. The place of arbitration shall be McKinney, Texas. Texas law shall apply. Judgment on the award rendered by the
                        arbitrator(s) may be entered in any court having jurisdiction thereof. Any costs or attorneys fees incurred by Company in defending any lawsuit in the
                        Courts of the State of Texas will also be paid by you.
                        </p>
                        <p></p>
                        <p>
                        You hereby waive any objections to such jurisdiction or venue.
                        Notwithstanding anything herein to the contrary, The Company shall not be liable for any losses arising out of the delay or interruption of its
                        performance of any obligations due to any act of God, act of governmental authority, act of public enemy, war, riot, flood, civil commotion, insurrection,
                        severe weather conditions, or any other cause beyond its reasonable control.
                        </p>
                        <p></p>
                        <p>
                        The Company may provide notice to you via the e-mail address you provided during registration or by a general notice on the Site. You may give notice to
                        The Company by Certified Mail or overnight courier to The Company Member Support at <strong>2655 Ulmerton RD STE 121 Clearwater, FL 33762</strong>
                        </p>
                        <p></p>
                        <p>
                        These Terms and Conditions and the Privacy Notice constitute the sole and only agreement of you and The Company and supersede any prior 
                        understandings or written or oral agreements between you and The Company. In the event any provision hereof is determined to be invalid or 
                        unenforceable, such invalidity or unenforceable shall not affect the validity or enforceability of the remaining provisions, but such provision shall be 
                        reformed, if reasonably possible, only to the extent necessary to conform with the parties intent.
                        </p>
                </div>
            </section>

            <section className="MembershipTerms__customer-service-container">
                <div className="MembershipTerms__customer-service">
                    <ContactCard />
                </div>
            </section>
        </>
    );
};

export default MembershipTerms;