import React, {useEffect} from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import unsubscribeBannerImg from '../../utils/assets/cancelService/unsubscribe-our-services.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import Cancelation from '../components/Cancelation/Cancelation';

 

const CancelService = props => {
    useEffect(()=>{
        window.scroll(0,0);
     },[])
     const legendInfo =
     {
         cardTitle: "Would you like to opt out from a service?",
         cardButtonText: "",
         lengendBackground: unsubscribeBannerImg
     }
     useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
            <BannerSection props={legendInfo}/>
            <NavegationInfo page="Cancel service"/>
            <Cancelation/>
            
            
        </React.Fragment>
    );
};

export default CancelService;