import React from "react";

import './NavegationInfo.css';
import backgroundDesign1 from '../../../utils/assets/logo/background-design1.png';



const NavegationInfo = props => {


    return (
        <section className="NavegationInfo__section-container" >
            <div className="NavegationInfo__background-design1">
                <div>
                    <div>
                    <img src={backgroundDesign1} alt= "bg-design-1" className="NavegationInfo__design1"/>
                    </div>
                </div>
            </div>
            <div className="NavegationInfo__information">
                <span> Home {">>"} {props.page} </span>
            </div>
        </section>
    );
};

export default NavegationInfo;