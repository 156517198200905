import React, { useEffect } from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import MembershipTerms from '../components/MembershipTerms/MembershipTerms';

import termsOfServiceBannerImg from '../../utils/assets/termsOfService/terms-and-conditions-telemedicine.png';
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

const TermsOfService = props => {
    useEffect(()=>{
        window.scroll(0,0);
     },[])

    const bannerSectionInfo =
     {
        cardTitle: "Terms and conditions for the use of this service",
        cardButtonText: "Make a Call",
        lengendBackground: termsOfServiceBannerImg
     }
    

    return (
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="Terms of service"/>
           <MembershipTerms/> 
        </React.Fragment>
    );
};

export default TermsOfService;