import React from "react";

import './Cancelation.css'
import CancelationForm from '../CancelationForm/CancelationForm';
import ContactInformation from '../../../shared/components/ContactInformation/ContactInformation';

import backgroundDesing1 from '../../../utils/assets/logo/background-design3.png';
import backgroundDesing2 from '../../../utils/assets/logo/background-design-right.png';
import backgroundDesing3 from '../../../utils/assets/logo/background-design2.png';


const Cancelation = props => {

    const cancelInformation = {
        contactInformationTitle: "Notify us if you have a cancellation request",
        contactInformationWeek: "Monday - Friday 9:00 AM - 09:00 PM EST",
        contactInformationWeekend: "Saturday - Sunday 9:00 AM - 05:00 PM EST"
    }


    return (
        <section className="Cancelation__main-container">
            
            <div className="Cancelation__background-design-1">
                    <div>
                    <img src={backgroundDesing1} alt="bg-design-1" className="Cancelation__design1" />
                    </div>
            </div>
           

            <div className="Cancelation__contact-information-container" >
                <div className="Cancelation__contact-information">
                    <ContactInformation props={cancelInformation}></ContactInformation>
                </div>
            </div>
            <div className="Cancelation__contact-form-container"  >
                <div className="Cancelation__contact-form"  >
                    <CancelationForm></CancelationForm>
                </div>
            </div>
           
            <div className="Cancelation__background-design-2">
                <div>
                    <img src={backgroundDesing2} alt="bg-design-2" className="Cancelation__design2" />
                </div>
            </div>
            <div className="Cancelation__background-design-3">
                <div>
                    <img src={backgroundDesing3} alt="bg-design-3" className="Cancelation__design3" />
                </div>
            </div>
        </section>
    );
};

export default Cancelation;