import React from 'react';

import './PaymentInfo.css'
import PaymentForm from '../PaymentForm/PaymentForm';

import backgroundDesing1 from '../../../utils/assets/logo/background-design-left.png';
import backgroundDesign2 from '../../../utils/assets/logo/background-design2.png';
import backgroundDesign3 from '../../../utils/assets/logo/background-design2.png';



const PaymentInfo = () => {
    return (
        <section className='PaymentInfo__main-section'>
            <div className="PaymentInfo__background-design-1">
                <div>
                    <img src={backgroundDesing1} alt="bg-design-1" className="PaymentInfo__design1" />
                </div>
            </div>
            <div className="PaymentInfo__background-design-2">
                <div>
                    <img src={backgroundDesign2} alt="bg-design-2" className="PaymentInfo__design2" />
                </div>
            </div>
            <div className="PaymentInfo__background-design-3">
                <div>
                    <img src={backgroundDesign3} alt="bg-design-3" className="PaymentInfo__design3" />
                </div>
            </div>
            <div className='PaymentInfo__main-title-container'>
                <h1 className="PaymentInfo__title p-4">Get ready to live healthier</h1>
            </div>
            <div className='PaymentInfo__payment-form-container'>
                <PaymentForm></PaymentForm>
            </div>
        </section>
    );
};

export default PaymentInfo;
