import React, {useEffect} from "react";


import BannerSection from "../../shared/components/BannerSection/BannerSection";
import Refund from "../components/Refund/Refund";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";

import refundPolicyImg from '../../utils/assets/refundPolicy/get-your-money-back.png';





const RefundPolicy = props => {
    const bannerSectionInfo =
    {
        cardTitle: "Unhappy with your experience?",
        cardButtonText: "",
        lengendBackground: refundPolicyImg
    }
    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])
    return (
        <React.Fragment>
         
            <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="Refund policy"/>
            <Refund />
       
            
           
            
        </React.Fragment>
    );
};

export default RefundPolicy;