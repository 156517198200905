import React, {useEffect} from 'react';
import checkoutBannerImg from '../../utils/assets/checkout/telemedicine-help-you-to-have-helthcare-with-you.png';
import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import PaymentInfo from '../components/PaymentInfo/PaymentInfo';
const Checkout = () => {

    useEffect(()=>{
        window.scroll(0,0);
     },[])
    const bannerSectionInfo =
    {
       cardTitle: "Thank you for becoming a member of our community",
       cardButtonText: "",                    
       lengendBackground: checkoutBannerImg
    }

    useEffect(()=>{
        window.scroll(0, 0);
    })
    return (
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
           <NavegationInfo page="Checkout"/>
           <PaymentInfo/>
        </React.Fragment>
    );
};

export default Checkout;
            