import ContactCard from "../shared/components/ContactCard/ContactCard";
const DeliveryPolicy = () => {
    return ( 

<section className="SecurityPolicy__main-container">
            <h1 className='SecurityPolicy__title'>On Call Health Group Delivery Policy</h1>
            <p className='SecurityPolicy__update'>Last updated: 10/03/2024</p>
            <div className="SecurityPolicy__parr">
                <p>
                    At <strong>On Call Health Group</strong>, we offer online subscriptions to our customers and, as part of our service, provide a welcome letter to all new subscribers. Below is our delivery policy for the <b>welcome letter</b>.

                </p>
            </div>

            <div className="SecurityPolicy__parr">
                <p> <b>1. Welcome Letter Delivery Time</b> </p>
                <ul className="SecurityPolicy__ul">
                    <li>
                    After confirming a subscription, customers will receive a <b>welcome letter</b> via email within 24 hours.

                    </li>
                    <li>
                    In case the welcome letter does not arrive within this time frame, we recommend checking your spam or junk mail folder. If it’s still missing, please contact our support team.

                    </li>
                </ul>
            </div>

            <div className="SecurityPolicy__parr">
                <p><b>2. Delivery Method</b></p>
                <ul className="SecurityPolicy__ul">
                    <li>
                    The <b>welcome letter</b> is delivered <b>exclusively via email</b>. We do not send physical products or printed materials as part of the subscription service.
                    </li>
                    <li>
                        The email will contain information about your subscription, how to access your online account, and other important details to get started with your subscription.
                    </li>
                </ul>
            </div>


            <div className="SecurityPolicy__parr">
                <p>3. Subscription Activation</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                        Once the welcome letter is received, your <b>online subscription</b> will be activated immediately, and you will have full access to the subscribed magazines and content.
                    </li>
                    <li>
                        No additional delivery of products is required for the subscription to function.
                    </li>
                </ul>

            </div>


            <div className="SecurityPolicy__parr">

                <p>4. Failed Deliveries</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                    If, for any reason, you do not receive the welcome letter or experience issues with accessing your online subscription, please contact our support team at 
                    <strong> support@magazines24-7.com</strong> We will ensure the letter is sent and help to resolve any technical issues with access.
                    </li>
                </ul>

            </div>

            <div className='SecurityPolicy__component'>
                <ContactCard/>  
            </div>

        </section>

     );
}
 
export default DeliveryPolicy;