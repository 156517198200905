import './securityPolicy.css';
import ContactCard from '../../shared/components/ContactCard/ContactCard';
const SecurityPolicy = () => {
    return ( 
        <section className="SecurityPolicy__main-container">
            <h1 className='SecurityPolicy__title'>On Call Health Group Security Policy</h1>
            <p className='SecurityPolicy__update'>Last updated: 10/03/2024</p>
            <div className="SecurityPolicy__parr">
                <p>
                    At <strong>On Call Health Group</strong>, we take the security of our website and the protection of our users very seriously. Below are the guidelines we follow to ensure a secure environment for browsing and online transactions.
                </p>
            </div>

            <div className="SecurityPolicy__parr">
                <p> <b>1. Data Encryption</b> </p>
                <ul className="SecurityPolicy__ul">
                    <li>All traffic between our users and <b>On Call Health Group</b> is protected using the HTTPS protocol, ensuring the confidentiality and integrity of the data.</li>
                    <li>Payment transactions are processed through trusted third-party providers that comply with PCI DSS (Payment Card Industry Data Security Standard), ensuring that payment information is encrypted and not stored on our servers.
                    </li>
                </ul>
            </div>

            <div className="SecurityPolicy__parr">
                <p><b>2. Data Backup</b></p>
                <ul className="SecurityPolicy__ul">
                    <li>
                       We perform automatic backups of our database and product files, ensuring service continuity in case of technical failures.
                    </li>
                    <li>
                        Backups are stored in secure locations and are periodically verified to ensure their integrity and availability when needed.
                    </li>
                </ul>
            </div>


            <div className="SecurityPolicy__parr">
                <p>3. Threat Protection</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                        <strong>On Call Health Group</strong> uses a Web Application Firewall (WAF) that detects and blocks suspicious activities, protecting our site from attacks like SQL injection, cross-site scripting (XSS), and other malicious threats.
                    </li>
                    <li>
                        We implement malware detection tools and continuous monitoring to identify and mitigate potential security threats before they affect our users.
                    </li>
                </ul>

            </div>


            <div className="SecurityPolicy__parr">

                <p>4. Site Monitoring</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                        Our team continuously monitors site activity to detect abnormal traffic patterns and suspicious behavior.
                    </li>
                    <li>
                        Periodic reviews of security event logs are conducted to identify any unauthorized access attempts or potential vulnerabilities.
                    </li>
                </ul>

            </div>


            <div className="SecurityPolicy__parr">
                <p>5. User Information Protection</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                        Although <b>On Call Health Group</b> does not store sensitive information such as passwords or credit card data, we ensure that any personal data we collect, like email addresses or mailing addresses, is protected through encryption and controlled access. 
                    </li>
                    <li>
                        Any data we collect is used exclusively for managing subscriptions and processing product deliveries.
                    </li>
                </ul>
            </div>

            <div className="SecurityPolicy__parr">
                <p>6. Incident Response</p>
                <ul className="SecurityPolicy__ul" >
                    <li>If any security breach is detected, our security team follows a rapid response protocol that includes identifying, containing, and resolving the issue.
                    </li>
                    <li>
                     Should the incident affect user data, <b>On Call Health Group</b> is committed to immediately notifying affected users and providing all relevant information about the impact and corrective actions taken.
                    </li>
                </ul>
            </div>

            <div className="SecurityPolicy__parr">
                <p>7. Updating and Patching</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                        <b>On Call Health Group</b> keeps its software, e-commerce platforms, and content management systems updated with the latest security patches to prevent known vulnerabilities.
                    </li>
                    <li>
                        We regularly review and update our security infrastructure to align with best practices and emerging cybersecurity threats.
                    </li>
                </ul>
            </div>

            <div className="SecurityPolicy__parr">
                <p>8. User Privacy</p>
                <ul className="SecurityPolicy__ul">
                    <li>
                     At <b>On Call Health Group</b>, we respect the privacy of our users. All personal information is handled in accordance with our Privacy Policy, which complies with international data protection regulations, such as the General Data Protection Regulation (GDPR).
                    </li>
                    <li>

                    We do not share personal information with third parties without the user's explicit consent, except to fulfill legal requests or complete transactions.
                    </li>

                </ul>
            </div>


            <div className="SecurityPolicy__parr">
                <ul className="SecurityPolicy__ul">
                    <li>
                        Our technical team receives regular training on the latest security best practices, ensuring that our development and site maintenance processes follow the most current security standards.
                    </li>
                </ul>
            </div>

            <div className='SecurityPolicy__component'>
                <ContactCard/>  
            </div>

        </section>
     );
}
 
export default SecurityPolicy;