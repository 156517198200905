import React from "react";
import './SubscriptionPlan.css';

import PriceCard from "../PriceCard/PriceCard";
import ContactCard from "../ContactCard/ContactCard";

import backgroundDesing1 from '../../../utils/assets/home/background-design3.png';
import backgroundDesign2 from '../../../utils/assets/home/background-design2.png';
import backgroundDesign3 from '../../../utils/assets/home/background-design-left.png';





const SubscriptionPlan = ({ props }) => {


    return (
        <>
            <section className="SubscriptionPlan__main-section-container">
                <div className="SubscriptionPlan__main-title">
                    <h1>The health care plans that are available to you</h1>
                </div>
                
                <div className="SubscriptionPlan__background-design-1"> 
                    <div>
                        <img src={backgroundDesing1} alt="bg-design-1" className="SubscriptionPlan__design1"/>
                    </div>
                </div>
                <div className="SubscriptionPlan__background-design-2">
                    <div>
                        <img src={backgroundDesign2} alt="bg-design-2" className="SubscriptionPlan__design2"/>
                    </div>
                </div>
                <div className="SubscriptionPlan__background-design-3">
                    <div>
                        <img src={backgroundDesign3} alt="bg-design-3" className="SubscriptionPlan__design3"/>
                    </div>
                </div>
                <div className="SubscriptionPlan__subscription-plans-container">
                    <div className="SubscriptionPlan__subscription-card-component-container">
                        {
                            props.map((product, index) => (
                                <PriceCard key={index} props={product} />
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className="SubscriptionPlan__card-contact-component-container">
                <div className="SubscriptionPlan__contact-card">
                    <ContactCard />
                </div>
            </section>
        </>
    );
};

export default SubscriptionPlan;