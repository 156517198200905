import React from "react";

import './PrivacyTerms.css'
import ContactCard from '../../../shared/components/ContactCard/ContactCard';

const PrivacyTerms = props => {

    return (
        <>
            <section className="PrivacyTerms__main-section-container">
                <div>
                    <h1 className="text-center PrivacyTerms__main-title">Privacy policy</h1>
                </div>
                <div className="PrivacyTerms__description">
                    <p>
                        <strong>ON CALL HEALTH GROUP</strong>, as well as our vendors, partners, consultants, and affiliates respect the privacy of its members and users of web site. Our
                        policy regarding privacy information that is collected and how that data is used is outlined below.
                    </p>
                    <p></p>
                    <p>
                        <strong>ON CALL HEALTH GROUP</strong> has developed all the system technologies, including the interfaces to the technology systems provided by third party
                        providers. <strong>ON CALL HEALTH GROUP</strong> manages the data security for this site. All data is stored in encrypted format that meets standards defined by
                        <b>HIPAA</b> (Health Insurance Portability and Accountability Act). All data transfer is executed using similar standards that meet or exceed <b>HIPAA</b>, and no data
                        is transferred to users that do not have specific data access keys.
                    </p>
                    <p></p>
                    <p>
                        The importance of security for all personal information associated with you, your family, and that which you share with your primary care physician (PCP),
                        is of extreme concern to us. At <strong>ON CALL HEALTH GROUP</strong>, we put into practice state of the art care in providing secure transmission of your information
                        from your personal computer to our servers. Personal information collected by our web site is stored in secure operations environments that are not
                        available to the public; and firewalled. Only those employees who require access to your information in order to do their jobs are allowed access, and
                        each have signed a confidentiality agreement that is on-file. Any employee who violates our privacy or security policies is subject to disciplinary action, up
                        to, and including, termination and civil and/or criminal prosecution.
                    </p>
                    <p></p>
                    <p>
                        Any information that you submit to us via our Web site – whether a survey, registration form, personal history form, or e-mail will be used exclusively by
                        the consulting physicians and only for the specific reason for which they were submitted. We take every reasonable precaution to protect your personal
                        information including encryption and passwords.
                        <strong>ON CALL HEALTH GROUP</strong> is the sole owner of the information collected on this site. We will not sell, share or lease this information to others. <strong>ON
                            CALL HEALTH GROUP </strong> does not sell, customer lists, e-mail addresses, cookies or other data.
                    </p>
                    <p></p>
                    <p>
                        Cookies are data stored on the user’s hard drive containing information about the user’s browsing history. Usage of a cookie is not linked to any
                        personally identifiable information while visiting the <strong>ON CALL HEALTH GROUP</strong> website. Once users close their browser, cookies simply terminate. <strong>ON
                            CALL HEALTH GROUP</strong> software never uses cookies to retrieve information from your computer that was not already originally sent in a cookie. Except
                        as described in this Policy, we do not use information transferred through cookies for any promotional or marketing purposes, nor is that information
                        shared with any third parties whatsoever.
                    </p>

                    <p className="text-decoration-underline">
                        1. Acceptance
                    </p>
                    <p></p>
                    <p>
                        By using this site and the <strong>ON CALL HEALTH GROUP</strong> service, you signify your acceptance of this Privacy Policy, as stated. If you do not agree to this
                        policy, you should not continue, and you should not use our service. Should we make any changes to the <strong>ON CALL HEALTH GROUP </strong>Privacy Policy, we
                        will post these changes prominently so users are always aware of what information we collect, how we use it, and under what circumstances, if any, we
                        disclose it. We recommend that you read this Privacy Policy each time you use our Web site in case you missed our notice of changes to the Privacy
                        Policy. Your continued use of the services and site following the posting of changes to these terms will mean you accept those changes.
                        <strong>ON CALL HEALTH GROUP</strong> welcomes your questions and comments about privacy, in general, and our privacy policy, specifically. If you have any
                        questions about our policies or our site, please Contact Us.
                    </p>
                    <p></p>
                    <p className="text-decoration-underline">
                        2. Notice of HIPAA Privacy Practices
                    </p>
                    <p></p>
                    <p>
                        <b>
                            THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, AND DISCLOSED, AND HOW YOU CAN GET ACCESS TO
                            THIS INFORMATION. PLEASE REVIEW IT CAREFULLY AND REPORT ANY ISSUES, OR CONCERNS, TO: ON CALL
                            HEALTH GROUP HIPAA </b> Compliancy Department at <b>2655 Ulmerton RD STE 121 Clearwater, FL 33762, USA ; PHONE: (844) 984-0551</b>
                    </p>
                    <p></p>
                    <p>
                        The Health Insurance Portability & Accountability Act of 1996 <b> (HIPAA) </b>is a federal program requiring that all medical records and other individually
                        identifiable health information used, or disclosed, by us in any form, whether electronically, on paper, or orally, are kept properly confidential. This Act
                        gives you, the Patient, significant new rights to understand and control how your health information is used. <b>HIPAA</b> provides penalties for covered
                        entities that misuse personal health information. We have prepared this “Notice of <b>HIPAA</b> Privacy Practices” to explain how we are required to maintain
                        the privacy of your health information and how we may use and disclose your health information.
                    </p>
                    <p></p>
                    <p>
                        We may use and disclose your medical records for each of the following purposes: treatment, payment, and health care operations:
                    </p>
                    <p></p>
                    <p>
                        <b> TREATMENT</b> means providing, coordinating, or managing health care and related services by one or more health care providers
                    </p>
                    <p></p>
                    <p>
                        <b>PAYMENT</b> means such activities as obtaining payment or reimbursement for services, billing or collection activities and utilization review.
                    </p>
                    <p>
                        <b>HEALTH CARE OPERATIONS </b> include managing your Electronic Medical Record to facilitate diagnostic medical consultations with associated physicians,
                        as well as conducting quality assessment review and service improvement planning activities, auditing functions, cost-containment analysis, and customers
                        service.
                    </p>
                    <p></p>
                    <ul className="PrivacyTerms__list">
                        <li>We may also create and distribute de-identified health information by removing all references to individually identifiable information.</li>
                        <li>We may contact you to provide information about our services or other health-related services that may be of interest to you.</li>
                        <li>Any other uses and disclosures will be made only with your written authorization. You may revoke such authorization in writing and we are required to
                            honor and abide by that written request, except to the extent that we have already taken actions relying on your authorization.
                        </li>
                    </ul>
                    <p></p>
                    <p>
                        You have the following rights with respect to your protected health information, which you can exercise by presenting a written request to the <strong>
                            ON CALL HEALTH GROUP </strong> <b>HIPPA</b>  Compliancy Department at <b> 2655 Ulmerton RD STE 121 Clearwater, FL 33762, USA ; PHONE: (844) 984-0551</b>
                    </p>
                    <ul className="PrivacyTerms__list">
                        <li>You have the right to ask for restrictions on the ways we use and disclose your health information for treatment, payment, and healthcare operations.
                            You may also request that we limit our disclosures to persons assisting your care. We will consider your request, but are not required to accept it.
                        </li>
                        <li>
                            You have the right to request that you receive communications containing your protected health information from us by alternative means or at
                            alternative locations. For example, you may ask that we only contact you at home or by mail.
                        </li>
                        <li>Except under certain circumstances, you have the right to inspect and copy medical, billing and other records used to make decisions about you. If 
                        you ask for copies of this information, we may charge you a nominal fee for copying, packaging, and postage.
                        </li>
                        <li>
                        If you believe that information in your records is incorrect, or incomplete, you have the right to ask us to correct the existing information, or add 
                        missing information. Under certain circumstances, we may deny your request, such as when the information is accurate and complete.
                        </li>
                        <li>
                        You have a right to receive a list of certain instances when we have used or disclosed your medical information. If you ask for this information from us
                        more than once every twelve months, charges may apply, to cover our costs for administration, archive retrieval, copying, packaging, and postage...
                        </li>
                    </ul>
                </div>
            </section>
            <section className="PrivacyTerms__customer-service-container">
                <div className="PrivacyTerms__customer-service">
                    <ContactCard />
                </div>
            </section>

        </>
    );
};

export default PrivacyTerms;