import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import './ProductDescription.css';

import backgroundDesign1 from '../../../utils/assets/home/background-design1.png';
import backgroundDesign2 from '../../../utils/assets/home/background-design2.png';
import backgroundDesign3 from '../../../utils/assets/home/background-design3.png';

const ProductDescription = ({ props }) => {
    const location = useLocation();
    let paragraphContent;

    switch (location.pathname) {
        case '/':
            paragraphContent = (
                <>
                    <p>
                        <b>
                            The On Call Health Group is a family of monthly healthcare
                            membership plans that are designed to save you both time
                            and money on medical services.
                        </b>
                    </p>
                    <br />
                    <p>
                        These plans provide virtual medical care 24 hours a day, 7
                        days a week, 365 days a year, covering multiple areas of your
                        health in a way that is personalized to you.
                    </p>
                    <br />
                    <p>
                        Get the fastest access to medical care for your family in as few
                        as <b>4 simple steps:</b>
                    </p>
                    <br />
                    <ol>
                        <li>All you need to do is download the app or go directly to
                            your health portal on the web and enter your <b> member ID
                                and password.
                            </b>
                        </li>
                        <li>
                            Select the specialty area you need. If you are unable to
                            determine the type of care you need, you may request &nbsp;
                            <b>assistance from our General Care area,</b> who will refer you
                            to a specialist.
                        </li>
                        <li>
                            After choosing your specialty, simply select the physician
                            of your choice and <b>start your virtual visit.</b>
                        </li>
                        <li>
                            Get an immediate diagnosis, medical prescriptions,
                            treatment plans, and <b> personal follow-up care.</b>
                        </li>
                    </ol>
                </>
            );
            break;

        case '/about-us':
            paragraphContent = (
                <>
                    <p>
                        <b>
                        On call Health Group is an authorized reseller of the services 
                        of GetLyric, Inc.
                        </b>
                    </p>
                    <br />
                    <p>
                        The company offers services related to online medical
                        consultations using videoconferencing, storing electronic
                        medical records, and many other services.
                    </p>
                    <br />
                    <p>
                        To access your membership login or go to <strong>www.getlyric.com</strong>&nbsp;
                        and enter your Member ID. Make the healthcare experience
                        smarter and more enjoyable for your family.
                    </p>
                    <br />
                    <p>
                        <b>Downloading our app, available for Apple or Android. </b> Use our
                        AI-powered mobile app to communicate with a doctor, ask
                        quick questions, or manage your medications.
                    </p>
                </>
            );
            break;

        default:
            paragraphContent = null;

    }

    return (



        <section className="ProductDescription__main-container" >

            <div className="ProductDescription__top-background-picture-container">
                <div>
                    <img src={backgroundDesign1} className="ProductDescription__top-top-picture" />
                </div>
                <div>
                    <img src={backgroundDesign2} className="ProductDescription__top-right-picture" />
                </div>
            </div>
            <div className="ProductDescription__inner-container">
                {(window.location.pathname === '/') ? (<>

                    <div className="ProductDescription__img-container">
                        <div className="ProductDescription__img-inner-container">
                            <img src={props.img} alt={props.title} className="ProductDescription__img" />
                        </div>
                    </div>

                    <div className="ProductDescription__explanation-container">
                        <div className="ProductDescription__title-container">
                            <h1>{props.title}</h1>
                        </div>
                        <div className="ProductDescription__explanation-container">
                            {paragraphContent}
                        </div>
                    </div>

                </>) : (
                    <>
                        <div className="ProductDescription__explanation-container">
                            <div className="ProductDescription__title-container">
                                <h1>{props.title}</h1>
                            </div>
                            <div className="ProductDescription__explanation-container">
                                {paragraphContent}
                            </div>
                        </div>
                        <div className="ProductDescription__img-container">
                            <div className="ProductDescription__img-inner-container">
                                <img src={props.img} alt={props.title} className="ProductDescription__img" />
                            </div>
                        </div>
                    </>
                )
                }
            </div>
            <div className="ProductDescription__left-background-picture-container">
                <div>
                    <img src={backgroundDesign3} className="ProductDescription__left-picture" />
                </div>

            </div>

        </section>



    );
};

export default ProductDescription;