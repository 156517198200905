import React from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import './BannerSection.css';

const   BannerSection = ( {props} ) => {

    return(
        <section className="BannerSection__main-section-container" style={{backgroundImage: `url(${props.lengendBackground})`}}> 
            <CardInfo props={props}></CardInfo>
        </section> 
    );
};


const CardInfo = ( { props }) =>{
    let cardBodyText;
    const history = useHistory();
    const phoneNumber = "8449840551";
    const goToHandle = () => {
        // history.push('/contact');
        switch(window.location.pathname){
            case '/privacy-policy':
            case '/terms-of-service':
                window.location.href = `tel:${phoneNumber}`;
                break;

            default:
                break;
        }
    }
    

    switch( window.location.pathname ){
        case '/':
            if( props.cardBannerPosition === 1){
                   cardBodyText = (
                    <p>
                        Have the peace of mind of knowing that a doctor is
                        available to you via video call 24 hours a day and all 
                        <span className="CardInfo__important-text"> at an incredible price for your pocket.</span>
                    </p>
                   ) 
            }
            else if (props.cardBannerPosition === 2){
                cardBodyText = (
                    <p>
                        <span className="CardInfo__important-text">A user-friendly virtual primary care experience</span> for
                        communicating with a physician, answering
                        questions, tracking care, or managing meds.
                    </p>
                )
            }
            else{
                cardBodyText =(
                    <p>
                        Save time and money with our exclusive telehealth
                        plans. Get care whenever and wherever you need it, &nbsp;
                        <span className="CardInfo__important-text">just a phone call away!</span>
                    </p>
                )
            }
            break;

        case '/price':

             cardBodyText=(
                <p>
                    With a simple video call, it is available 24/7,
                    anytime, anywhere. <span className="CardInfo__important-text"> Covers more than 85% of
                    common conditions seen in the ER. 
                    </span>
                </p>
             )

            break;
        
        case '/contact':
            cardBodyText = (
                <p>
                    With our national network of medical professionals
                    and advanced technology, <span className="CardInfo__important-text">we deliver expert care-
                    whenever you need it.  
                    </span>

                </p> 
            )
            break;
        
        case '/cancel-service':
            cardBodyText = (
                <p>
                    <span className="CardInfo__important-text">You can cancel your service very easily.</span> By
                    choosing your cancellation reason, we hope you will
                    help us improve our service. 
                </p>
            )
                
            break;

        case '/terms-of-service':
            cardBodyText=(
                <p>
                <span className="CardInfo__important-text">Please do not hesitate to contact us</span> if you have any
                questions or if you would like to receive more
                information.
                </p>
            )
            break;

        case '/privacy-policy':
                cardBodyText=(
                    <p>
                        At On call health group, your right to privacy is an 
                        important concern to us. <span className="CardInfo__important-text">Please feel free to contact
                        us with any questions and problems.
                        </span>
                    </p>
                )
        break; 
        case '/refund-policy':
                    cardBodyText=(
                        <p>
                            <span className="CardInfo__important-text">Please let us know what went wrong and how we
                            can improve.</span> To request a refund, please follow
                            these steps 
                        </p>
                    )
        break;
        case '/about-us': 
            cardBodyText=(
                <p>
                    <span className="CardInfo__important-text">Meet our team of experts and learn about the
                    benefits.</span> Join our community of members who are 
                    enjoying the world-class medical services we offer.                       
                </p>
            )
            break;
        case '/checkout':
            cardBodyText=( 
                <p>
                     <span className="CardInfo__important-text">Now you have access to an exclusive family care 
                     plan</span>  that includes 24/7 medical consultations,
                     prescription drug coverage, personalized treatment 
                     plans, and much more 
                </p>
            )
            break;
        default :
        cardBodyText=( 
            <p>
                What you were looking for may have moved or is 
                not available.&nbsp;
                <span className="CardInfo__important-text">Resource links are listed below.</span>  
            </p>
        )
        break;
    }

    
    return(
       
        <div className="BannerSection__main-card-info-container">

            <div className="BannerSection__main-title-container">
                <h2 className="text-left">{props.cardTitle}</h2>
            </div>
            <div className="BannerSection__main-body-container">
                {cardBodyText}
            </div>
            {
                props.cardButtonText.trim() !== "" ? (
                    <div className="BanneSection__main-btn-container">
                        <div className="BannerSection__main-button-container">
                        <div className="BannerSection__inner-button-container">
                            <a href="" onClick={(e) => { e.preventDefault(); goToHandle()}}>
                                {props.cardButtonText}
                            </a>
                        </div>
                    </div>
                    </div>
                ) : (
                    <div className="BannerSection__button-container" style={{ minWidth : '10em'}}>
                    </div>
                )
            }
        </div>
     
    )
}


export default BannerSection;