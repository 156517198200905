import React, {useEffect} from "react";


import privacyPolicyBannerImg from '../../utils/assets/privacyPolicy/privacy-policy-telemedicine.png';
import BannerSection from "../../shared/components/BannerSection/BannerSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import PrivacyTerms from "../components/PrivacyTerms/PrivacyTerms";


const PrivacyPolicy = props => {
    
    const bannerSectionInfo =
     {
        cardTitle: "How we use the information we collect about you",
        cardButtonText: "Call Us",
        lengendBackground: privacyPolicyBannerImg
     }
     useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <React.Fragment>
        <BannerSection props={bannerSectionInfo}/>
        <NavegationInfo page="Privacy policy"/>
        <PrivacyTerms/>
  
        </React.Fragment>
    );
};

export default PrivacyPolicy;