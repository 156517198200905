import React, { useEffect } from "react";

import BannerSection from '../../shared/components/BannerSection/BannerSection';
import NavegationInfo from '../../shared/components/NavegationInfo/NavegationInfo';
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import Faq from '../components/Faq/Faq';
import OurTeam from "../components/OurTeam/OurTeam";


import aboutUsBannerImg from '../../utils/assets/aboutUs/telemedicine-doctors-team.png';
import telehealthAppImg from '../../utils/assets/aboutUs/telehealth-app.png';




const AboutUs = props => {

   useEffect(()=>{
      window.scroll(0,0);
   },[])

    const bannerSectionInfo =
     {
        cardTitle: "Read more about who we are and what we do",
        cardButtonText: "Join us",
        lengendBackground: aboutUsBannerImg
     }

     const productDescription = {
        title : "Who are we?",
        img : telehealthAppImg
     }

    

           
    return(                    
        <React.Fragment>
           <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="About us"/>
            <ProductDescription props={productDescription} />
            <SectionInfo title={'Get to know the team'} color={'#2E84A6'}/>
            <OurTeam/>
           <Faq/>
        </React.Fragment>
    );
};

export default AboutUs;