import React, {useEffect} from "react";

import contactUsBannerImg from '../../utils/assets/contact/telemedicine-apoinment.png';
import BannerSection from "../../shared/components/BannerSection/BannerSection";
import NavegationInfo from "../../shared/components/NavegationInfo/NavegationInfo";
import ContactUs from '../components/ContactUs/ContactUs';




const Contact = props => {

    useEffect(()=>{
        window.scroll(0,0);
    })

    const bannerSectionInfo =
    {
       cardTitle: "Provide better health care for you and your family around the clock",
       cardButtonText: "",
       lengendBackground: contactUsBannerImg
    }

    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo}/>
            <NavegationInfo page="Contact"/>
            <ContactUs />

        </React.Fragment>
    );
};

export default Contact;