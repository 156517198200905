import React, { useEffect } from "react";

import BannerSection from "../../shared/components/BannerSection/BannerSection";
import ProductDescription from '../../shared/components/ProductDescription/ProductDescription';
import Benefit from "../components/Benefit/Benefit";
import SectionInfo from "../../shared/components/SectionInfo/SectionInfo";
import SubscriptionPlan from "../../shared/components/SubscriptionPlan/SubscriptionPlan";

import doctorsWithAComputerImg from '../../utils/assets/home/doctors-with-a-computer.png';
import accessToTlmedicine from '../../utils/assets/home/access-to-tlemedicine.png';
import telehealthWithADoctorImg from '../../utils/assets/home/telehealth-with-a-doctor.png';
import haveAVideoCallWithADoctorImg from '../../utils/assets/home/have-a-video-call-with-a-doctor.png';


const Home = props => {
    useEffect(()=>{
        window.scroll(0,0);
     },[])
    const bannerSectionInfo =
    [
        {
            cardTitle: "Seeing a medical professional when you need it most is now possible",
            cardBannerPosition : 1,
            cardButtonText: "Start Now",
            lengendBackground: doctorsWithAComputerImg
        },
        {
            cardTitle: "Put your family's health at the top of your priority list",
            cardBannerPosition : 2,
            cardButtonText: "know More",
            lengendBackground: telehealthWithADoctorImg
        },
        {
            cardTitle: "Smart healthcare investments for you and your loved ones",
            cardBannerPosition : 3,
            cardButtonText: "Contact",
            lengendBackground: accessToTlmedicine
        }

    ]

    const productDescription = {
        title: "Take a look at how our service works",
        img: haveAVideoCallWithADoctorImg
    }

    const subscriptionInformation = [
        {       
            subscriptionType: "BASIC",
            subscriptionPrice: "39.95",
            subscriptionTrialInfo: "Trial for 30 days at",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }, 
        {       
            subscriptionType: "PREMIUM",
            subscriptionPrice: "49.95",
            subscriptionTrialInfo: "Trial for 30 days at",
            subscriptionTrialPrice : "3.29",
            subscriptionDescription: "Monthly",
            subscriptionButtonText: "Buy"
        }
     
    ]

    useEffect(()=>{
        window.scroll(0,0);
    },[])
 
    return (
        <React.Fragment>
            <BannerSection props={bannerSectionInfo[0]}/>
            <ProductDescription props={productDescription}/>
            <BannerSection props={bannerSectionInfo[1]}/>
            <Benefit/>
            <BannerSection props={bannerSectionInfo[2]}/>
            <SectionInfo title={"Get a 30-day trial version for ONLY $3.29"} color={"#277A8C"}/> 
            <SubscriptionPlan  props={subscriptionInformation} />
        </React.Fragment>
    );
};

export default Home;