import React from "react";

import './ContactCard.css';



import contactCardIcon from '../../../utils/assets/icons/customer-service-icon.png';
import emailServiceIcon from '../../../utils/assets/icons/email-icon.png';


const  ContactCard = () => {

    return (


        <div className="ContactCard__information-container">
            <div className="ContactCard__contact-information">
                <div className="ContactCard__service-number-contact-container">
                    <div className="ContactCard__person-support-icon-container">
                        <img src={contactCardIcon} alt="Customer Service Icon"/>
                    </div>
                    <div className="ContactCard__service-number-container">
                    <p>
                    (844) 984-0551
                    </p>
                    </div>
                </div>

                <div className="ContactCard__email-contact-container">
                    <div className="ContactCard__email-icon-container">
                    <img src={emailServiceIcon} alt="Email Service Icon"/>
                    </div>
                    <div className="ContactCard__email-container">
                        <p>
                        support@oncallhealthgroup.com
                        </p>
                    </div> 
                </div>

            </div>
            <div className="ContactCard__description-information">
                <p>
                    <b>In case of emergency, dial 911</b> &nbsp;
                    immediately. If you need information
                    or assistance with billing issues. &nbsp;
                    <b>Please contact us!</b>
                </p>
            </div>
        </div>


    );
};

export default ContactCard;